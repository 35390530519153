import { Component, Vue } from "vue-property-decorator";

@Component
export default class ScrollComponent extends Vue {
  public pathHasSearch: boolean = false;

  mounted() {
    if (process.client) {
      window.addEventListener("scroll", this.onScroll);
    }
  }

  beforeDestroy() {
    if (process.client) {
      window.removeEventListener("scroll", this.onScroll);
    }
  }

  onScroll() {
    this.$store.commit("layout/setPosition", window.scrollY);
  
    if (window.scrollY === 0) {
      this.$store.commit("layout/setNotScrolled");
    } else {
      this.$store.commit("layout/setScrolled");
    }
  }
}
