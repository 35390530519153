import { render, staticRenderFns } from "./TheLogo.vue?vue&type=template&id=7b95058b&scoped=true"
import script from "./TheLogo.vue?vue&type=script&lang=ts"
export * from "./TheLogo.vue?vue&type=script&lang=ts"
import style0 from "./TheLogo.vue?vue&type=style&index=0&id=7b95058b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b95058b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutsHeaderTheImage: require('/usr/src/nuxt-app/components/layouts/header/TheImage.vue').default})
