
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { BIcon, BIconChevronUp } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconChevronUp,
  },
})
export default class TheBackToTop extends Vue {
  @Prop({ default: false }) hasChat!: boolean;

  public goBackToTop() {
    this.$scrollOntop();
  }
}
