
import ExtraFuncionsMenuLinks from "~/mixins/extramenulink";
import { Component, Prop } from "nuxt-property-decorator";
import { FooterData } from "~/types/components";
import { BIcon, BIconFacebook, BIconLinkedin, BIconTwitter, BIconYoutube } from "bootstrap-vue";

@Component({ components: { BIcon, BIconFacebook, BIconLinkedin, BIconTwitter, BIconYoutube } })
export default class TheFooterAstagiudiziaria extends ExtraFuncionsMenuLinks {
  @Prop() footerdata!: FooterData;

  public SOCIALS(): Array<any> {
    let arr: Array<any> = [];
    Object.keys(this.footerdata.socials).forEach((e) => {
      if (this.footerdata.socials[e]) {
        arr.push({ name: e, link: this.footerdata.socials[e] });
      }
    });
    return arr;
  }
}
