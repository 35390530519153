export const facebookScripts = (facebookId: string) => {
  if (!facebookId) return;

  let s = document.createElement("script");
  s.id = "fb-script-meta-pixel";
  s.type = "text/javascript";
  (s.innerHTML = `!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${facebookId}');fbq('track', 'PageView');`),
    document.head.appendChild(s);

  // let noscript = document.createElement("noscript");
  // let img = document.createElement("img");
  // img.height = 1;
  // img.width = 1;
  // img.style.display = "none";
  // img.src = `https://www.facebook.com/tr?id=${facebookId}&ev=PageView&noscript=1`;
  // noscript.innerHTML = img.outerHTML;
  // document.body.appendChild(noscript);
};
