
import { Component, Watch } from "nuxt-property-decorator";
import ScrollComponent from "@/mixins/scroll";
import { Getter } from "vuex-class";
import { HeaderData, FooterData } from "~/types/components";
import TheHeaderMaster from "~/components/layouts/TheHeaderMaster.vue";
import TheHeaderAstagiudiziaria from "~/components/layouts/TheHeaderAstagiudiziaria.vue";
import TheFooterMaster from "~/components/layouts/TheFooterMaster.vue";
import TheFooterAstagiudiziaria from "~/components/layouts/TheFooterAstagiudiziaria.vue";
import { cookieScripts } from "~/helpers/analytics/cookie-v2";
import { chatScripts } from "~/helpers/analytics/chat";
import { facebookScripts } from "~/helpers/analytics/facebook";
/*
per usare dinamicamente un'altro header/footer o si deve importare ed inserire nei componenti
*/

@Component({
  components: {
    TheHeaderMaster,
    TheHeaderAstagiudiziaria,
    TheFooterMaster,
    TheFooterAstagiudiziaria,
  },
})
export default class Layout extends ScrollComponent {
  @Getter("tenant/footerData") footerData!: FooterData;
  @Getter("tenant/headerData") headerData!: HeaderData;
  @Getter("tenant/theme") theme!: string;
  @Getter("tenant/completeDomain") domain: string;
  @Getter("tenant/gaIds") gaIds: string[];
  @Getter("tenant/cookieData") cookieData: any;
  @Getter("tenant/tidio") tidio: any;
  @Getter("tenant/facebookPixelId") facebookId: string;
  @Getter("tenant/isAsta") isAsta: boolean;
  @Getter("layout/offset") offset: any;

  @Watch("offset")
  onOffsetChanged() {
    if (process.client) {
      // @ts-ignore
      this.$store.commit("layout/setHeaderHeight", this.setHeaderHeight());
      // @ts-ignore
      this.$store.commit(
        "layout/setSearchWrapperHeight",
        document.getElementById("searchwrapper") ? document.getElementById("searchwrapper")?.offsetHeight : 0
      );
      // @ts-ignore
      this.$store.commit(
        "layout/setSearchBar",
        document.getElementById("searchbar") ? document.getElementById("searchbar")?.offsetHeight : 0
      );
      // @ts-ignore
      this.$store.commit(
        "layout/setFilterSummaryHeight",
        document.getElementById("searchFilterSummary") ? document.getElementById("searchFilterSummary")?.offsetHeight : 0
      );
      // @ts-ignore
      this.$store.commit(
        "layout/setCardSummaryHeight",
        document.getElementById("cardSummary") ? document.getElementById("cardSummary")?.offsetHeight : 0
      );
    }
  }

  mounted() {
    facebookScripts(this.facebookId);

    if (process.env.ENV !== "local") {
      cookieScripts(this.cookieData);
      chatScripts(this.tidio);
      facebookScripts(this.facebookId);
    }

    if (process.env.ENV !== "local" && this.isAsta) {
      let clarifyScript = document.createElement("script");
      clarifyScript.id = "microsoft-clarify";
      clarifyScript.type = "text/javascript";
      clarifyScript.innerHTML = `(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "nxcbvklf5n");`;
      document.head.appendChild(clarifyScript);
    }
  }

  public head() {
    return {
      htmlAttrs: {
        lang: "it",
      },
      meta: [
        {
          hid: "og:site_name",
          property: "og:site_name",
          content: this.headerData.name,
        },
        {
          hid: "og:url",
          property: "og:url",
          content: this.domain + this.$router.currentRoute.fullPath,
        },
      ],
    };
  }

  setHeaderHeight() {
    // @ts-ignore
    let headerHeight = document.getElementById("header") ? document.getElementById("header").offsetHeight : 0;

    let tmpHeight = headerHeight - this.offset;
    if (tmpHeight && tmpHeight > 0) {
      return tmpHeight;
    }
    return 0;
  }

  /**
   * @description
   * @param {string} prefix - Header, Footer, etc
   * @returns {string} - TheHeaderMaster, TheHeaderAstagiudiziaria, etc
   */
  public getTheme(prefix: string): string {
    let componentName = prefix + this.theme;

    if (this.$options.components) {
      return componentName in this.$options.components ? componentName : prefix + "Master";
    }
    return prefix + "Master";
  }
}
