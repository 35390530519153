import { CookieData } from "~/types/responses/inserzione/ResponseInserzioneInterface";

export const cookieScripts = (cookieData: CookieData) => {
  if (cookieData.siteId === null || cookieData.apiKey === null || cookieData.cookiePolicyId === null) return;

  let s1 = document.createElement("script");
  s1.id = "cookie-config";
  s1.type = "text/javascript";
  s1.async = true;
  (s1.innerHTML = `
  var _iub = _iub || [];
  _iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"countryDetection":true, "enableFadp":true, "enableLgpd":true,"enableUspr":true,"floatingPreferencesButtonDisplay":"bottom-left", "inlineDelay":5000, "lgpdAppliesGlobally":false,"perPurposeConsent":true,"siteId":${cookieData.siteId},"whitelabel":false,"cookiePolicyId":${cookieData.cookiePolicyId},"lang":"it", "banner":{ "acceptButtonDisplay":true,"backgroundOverlay":true,"closeButtonDisplay":false,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"bottom","rejectButtonDisplay":true }};`),
    document.head.appendChild(s1);

  let s2 = document.createElement("script");
  s2.id = "cookie-stub";
  s2.type = "text/javascript";
  s2.src = "//cdn.iubenda.com/cs/gpp/stub.js";
  document.head.appendChild(s2);

  let s3 = document.createElement("script");
  s3.type = "text/javascript";
  s3.id = "cookie-cs";
  s3.async = true;
  s3.charset = "UTF-8";
  // s3.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
  s3.src = "//cdn.iubenda.com/cookie_solution/iubenda_cs/1.51.1/core-it.js";
  document.head.appendChild(s3);

  let s4 = document.createElement("script");
  s4.type = "text/javascript";
  s4.id = "cookie-api-key";
  s4.async = true;
  s4.innerHTML = `var _iub = _iub || {}; _iub.cons_instructions = _iub.cons_instructions || []; _iub.cons_instructions.push(["init", {api_key: "${cookieData.apiKey}"}]);`;
  document.head.appendChild(s4);

  let s5 = document.createElement("script");
  s5.type = "text/javascript";
  s5.id = "cookie-cons";
  s5.async = true;
  // s5.src = `https://cdn.iubenda.com/cons/iubenda_cons.js`;
  s5.src = "//cdn.iubenda.com/cons/iubenda_cons/core-8fdcb29dc2a89f2172df78c5f7cb048f.js";
  document.head.appendChild(s5);
};